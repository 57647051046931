<template>
	<div class="wrapper">
		<b-table :items="data" :fields="fields"></b-table>
	</div>
</template>

<script>
export default {
	name: 'IssueTableView',
	props: {
		data: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			fields: [
				{ key: 'id', sortable: true },
				{ key: 'timestamp', sortable: true },
				{ key: 'name', sortable: true },
				{ key: 'project', sortable: true },
				{ key: 'type', sortable: true },
				{ key: 'severity', sortable: true },
				{ key: 'title', sortable: true },
				{ key: 'description', sortable: true },
				{ key: 'developer', sortable: true },
				{ key: 'resolved', sortable: true },
				{ key: 'status', sortable: true },
			],
			loaded: false,
		};
	},
};
</script>

<style scoped lang="scss">
.wrapper {
	background: white;
}
</style>
